/* @import url('https://fonts.googleapis.com/css?family=Roboto'); */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap'); */
@import './theme/variables.css';
@import './theme/fonts.css';

*,
html,
body,
body * {
  font-family: 'Campton', sans-serif !important;
}

:root {
  /* --ion-safe-area-top: 20px;
  --ion-safe-area-bottom: 22px; */
  --ion-safe-area-top: constant(safe-area-inset-top);
  --ion-safe-area-bottom: constant(safe-area-inset-bottom);
  --ion-safe-area-left: constant(safe-area-inset-left);
  --ion-safe-area-right: constant(safe-area-inset-right);

  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
  --ion-safe-area-left: env(safe-area-inset-left);
  --ion-safe-area-right: env(safe-area-inset-right);
}

:root:root {
  --mdc-typography-font-family: unquote('Roboto, serif');
  --mdc-theme-primary: var(--ion-color-primary);
  /* --mdc-theme-secondary: #0ac775; */
  --mdc-theme-secondary: var(--ion-color-secondary);
  --mdc-theme-surface: #ffffff;
  --mdc-theme-background: #f7f7f7;
  --mdc-theme-error: #f24965;
  --mdc-theme-on-background: #858585;
  --mdc-theme-on-primary: white;
  --mdc-theme-on-secondary: white;
  --mdc-theme-on-error: white;
  --mdc-theme-on-surface: #858585;
  --mdc-theme-text-primary-on-background: #858585;
  --mdc-theme-text-secondary-on-background: #858585;
  --mdc-theme-text-disabled-on-background: #858585;
  --mdc-theme-text-primary-on-light: #333;
  --mdc-theme-text-secondary-on-light: #d1d1d1;
  --mdc-theme-text-hint-on-light: white;
  --mdc-theme-text-disabled-on-light: white;
  --mdc-theme-text-icon-on-light: white;
  --mdc-theme-text-primary-on-dark: white;
  --mdc-theme-text-secondary-on-dark: white;
  --mdc-theme-text-hint-on-dark: white;
  --mdc-theme-text-disabled-on-dark: white;
  --mdc-theme-text-icon-on-dark: white;
  --mdc-theme-text-icon-on-background: #45cae6;
}

#mount:not(.ios) {
  height: 100%;
  display: grid;
  grid-template-columns: fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'main'
    'footer';
}

#mount ion-app.ion-page {
  margin-top: max(1.5rem, env(--safe-area-inset-top));
  /* margin-left: max(1.5rem, env(--safe-area-inset-left));
  margin-right: max(1.5rem, env(--safe-area-inset-right)); */
  margin-bottom: env(--safe-area-inset-bottom);
}

html,
html[buildfire],
body,
html[buildfire] body,
#mount {
  /* min-height: 100%;
  height: auto !important; */
  /* overflow: auto !important; */
  height: 100% !important;
  font-family: Roboto !important;

  /* To smooth any scrolling behavior */
  -webkit-overflow-scrolling: touch;
}

* {
  font-family: Roboto, serif;
}

/* html[safe-area='true'] body {
  bottom: calc(0px + env(safe-area-inset-bottom)) !important;
  bottom: calc(0px + constant(safe-area-inset-bottom)) !important;
}
html[safe-area='true'] body .mdc-dialog {
  padding-bottom: calc(0px + env(safe-area-inset-bottom)) !important;
  padding-bottom: calc(0px + constant(safe-area-inset-bottom)) !important;
} */

.ion-no-border::after {
  content: '';
  display: block;
  position: absolute;
  height: 1px;
  bottom: -1px;
  width: 100%;
  background-color: var(--ion-color-medium);
  opacity: 0.5;
}

/* Needs to be 1px less than SplitPane's cutoff for showing 2 panels */
@media (max-width: 767px) {
  .hide-sm {
    display: none;
  }
}

.quill-file {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  display: block;
}

.quill-file_anchor {
  display: flex;
  flex-direction: row;
  color: inherit !important;
  text-decoration: none !important;
}

.quill-file_svg-wrapper .ionicon {
  width: 50px;
  height: 50px;
  opacity: 0.6;
  stroke: currentColor;
  fill: none;
  stroke-width: var(--ionicon-stroke-width, 32px);
}

.quill-file_text-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.quill-file_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration-line: underline;
}
